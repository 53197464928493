import { useState } from 'react'
import { Meta } from 'utils/meta'

import { Categories, DemoWork, Feedback, Promo, Search } from '@/ui'

import { useDebounce } from '@/hooks'

import { IProduct, IStock, IWork } from '@/types'

export const HomeScreen = ({
	product,
	work,
	stocks,
}: {
	product: IProduct[]
	work: IWork[]
	stocks: IStock[]
}) => {
	const [searchTerm, setSearchTerm] = useState('')
	const debouncedSearch = useDebounce<string>(searchTerm, 500)

	return (
		<Meta description="Оперативная типография. Производство рекламных конструкций, вывесок, печать визиток листовок буклетов, изготовление зеркал с подстветкой и без. Низкие цены, оперативное производство.">
			<Promo stocks={stocks} />
			<Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
			<Categories
				products={product.filter((item: any) =>
					item.name
						.toLocaleLowerCase()
						.includes(debouncedSearch.toLocaleLowerCase())
				)}
			/>
			<DemoWork work={work} />
			<Feedback />
		</Meta>
	)
}

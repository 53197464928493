import { AlignRightOutlined, CloseOutlined } from '@ant-design/icons'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { isShowMenu, logout, useAppDispatch, useAppSelector } from '@/store'

import {
	ADMIN_INDEX_PATH,
	ADMIN_PRODUCT_ADD_PATH,
	ADMIN_PRODUCT_EDIT_PATH,
	ADMIN_PRODUCT_PATH,
	ADMIN_STOCK_PATH,
	ADMIN_WORKS_PATH,
	MAIN_PATH,
} from '@/const'

export const HeaderAdmin = () => {
	const isShowMenuHtml = useAppSelector((state) => state.app.isShowMenu)
	const dispatch = useAppDispatch()
	const { asPath } = useRouter()

	const onShowMenu = () => {
		dispatch(isShowMenu(true))
		document.getElementById('body')?.classList.add('_fixed')
	}
	const onCloseMenu = () => {
		dispatch(isShowMenu(false))
		document.getElementById('body')?.classList.remove('_fixed')
	}
	return (
		<div className="headerAdmin" style={{ paddingTop: 20 }}>
			<div
				className="container"
				style={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<div className="headerAdmin__top">
					<Link className="headerAdmin__logo" href={MAIN_PATH}>
						На сайт
					</Link>
					<button
						className="headerAdmin__burger"
						type="button"
						onClick={onShowMenu}
					>
						<AlignRightOutlined />
					</button>
				</div>
				<div className={`headerAdmin__nav menu ${isShowMenuHtml && '_open'}`}>
					<button className="menu__burger" type="button" onClick={onCloseMenu}>
						<CloseOutlined />
					</button>
					<nav className="menu__nav">
						<ul className="menu__list">
							<li className="menu__item">
								<Link
									href={ADMIN_INDEX_PATH}
									title="Наши работы"
									className={`menu__link ${asPath === ADMIN_INDEX_PATH && 'active'}`}
								>
									Главная
								</Link>
							</li>
							<li className="menu__item">
								<Link
									href={ADMIN_PRODUCT_PATH}
									title="Контакты"
									className={`menu__link ${asPath === ADMIN_PRODUCT_PATH ? 'active' : asPath === ADMIN_PRODUCT_ADD_PATH ? 'active' : asPath.includes(ADMIN_PRODUCT_EDIT_PATH) && 'active'}`}
								>
									Продукты
								</Link>
							</li>
							<li className="menu__item">
								<Link
									href={ADMIN_STOCK_PATH}
									title="Акции"
									className={`menu__link ${asPath === ADMIN_STOCK_PATH && 'active'}`}
								>
									Акции
								</Link>
							</li>
							<li className="menu__item">
								<Link
									href={ADMIN_WORKS_PATH}
									title="Контакты"
									className={`menu__link ${asPath === ADMIN_WORKS_PATH && 'active'}`}
								>
									Работы
								</Link>
							</li>
							<li>
								<button
									onClick={() => dispatch(logout())}
									className="menu__link"
								>
									Выход
								</button>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	)
}

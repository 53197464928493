import Link from 'next/link'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { IProduct } from 'types/Product.type'

import { FeedbackService } from '@/api'

import { nameField } from '@/utils'

interface IForm {
	name: string
	message?: string
	phone: string
}

type Choice = 'phone' | 'telegram' | 'whatsApp'

export function FeedbackProduct({ product }: { product: IProduct }) {
	const [choice, setChoice] = useState<Choice>('phone')

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm<IForm>()

	const onSubmit: SubmitHandler<IForm> = async (data) => {
		await FeedbackService.newFeedbackWithProduct({
			...data,
			productName: product.name,
			typeConnection: choice,
			reset: () => {
				reset()
				setChoice('phone')
			},
		})
	}

	return (
		<section className="feedback-product">
			<div className="container">
				<div className="feedback-product__title">Закажите звонок</div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="feedback-product__inner"
				>
					<div className="feedback-product__item">
						<div className="feedback-product__input-wrap">
							<label className="feedback-product__lable" htmlFor="name">
								Имя: <span>*</span>
							</label>
							<div style={{ width: '100%' }}>
								<input
									className="feedback-product__input"
									id="name"
									type="text"
									placeholder="Ваше имя"
									{...register('name', nameField)}
								/>
								{errors.name && (
									<div className="input__error">{errors.name.message}</div>
								)}
							</div>
						</div>
					</div>
					<div className="feedback-product__item">
						<div className="feedback-product__choice-wrap">
							<legend
								style={{ width: '100%', fontSize: '20px', fontWeight: 700 }}
							>
								Выберите способ связи:{' '}
								<span style={{ color: 'red', marginLeft: 5 }}>*</span>
							</legend>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-evenly',
									marginTop: 10,
								}}
							>
								<div>
									<input
										type="radio"
										id="phone_radio"
										name="drone"
										value="phone"
										checked={choice === 'phone'}
										onChange={() => setChoice('phone')}
									/>
									<label htmlFor="phone_radio" style={{ fontSize: 18 }}>
										Телефон
									</label>
								</div>
								<div>
									<input
										type="radio"
										id="telegram_radio"
										name="drone"
										value="telegram"
										checked={choice === 'telegram'}
										onChange={() => setChoice('telegram')}
									/>
									<label htmlFor="telegram_radio" style={{ fontSize: 18 }}>
										Telegram
									</label>
								</div>
								<div>
									<input
										type="radio"
										id="whatsapp_radio"
										name="drone"
										value="whatsapp"
										checked={choice === 'whatsApp'}
										onChange={() => setChoice('whatsApp')}
									/>
									<label htmlFor="whatsapp_radio" style={{ fontSize: 18 }}>
										WhatsApp
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="feedback-product__item">
						<div className="feedback-product__input-wrap">
							<label className="feedback-product__lable" htmlFor="phone">
								{choice === 'phone' && (
									<>
										Телефон: <span>*</span>
									</>
								)}
								{choice === 'telegram' && (
									<>
										Telegram: <span>*</span>
									</>
								)}
								{choice === 'whatsApp' && (
									<>
										WhatsApp: <span>*</span>
									</>
								)}
							</label>
							<div style={{ width: '100%' }}>
								<Controller
									name="phone"
									control={control}
									defaultValue=""
									rules={{
										required: {
											value: true,
											message: 'Поле обязательно для заполнение',
										},
									}}
									render={({ field }) => (
										<InputMask
											value={field.value}
											onChange={field.onChange}
											mask="+7 (999) 999-99-99"
											className="feedback-product__input"
											placeholder="+7 (___) ___-__-__"
										>
											{(inputProps: any) => <input {...inputProps} />}
										</InputMask>
									)}
								/>
								{errors.phone && (
									<div className="input__error">{errors.phone.message}</div>
								)}
							</div>
						</div>
					</div>
					<div className="feedback-product__item">
						<div className="feedback-product__input-wrap">
							<label className="feedback-product__lable" htmlFor="message">
								Сообщение:
							</label>
							<div style={{ width: '100%' }}>
								<Controller
									name="message"
									control={control}
									defaultValue=""
									rules={{
										required: false,
									}}
									render={({ field }) => (
										<textarea
											value={field.value}
											cols={3}
											rows={4}
											onChange={field.onChange}
											className="feedback-product__input"
											style={{ maxWidth: 655 }}
											placeholder="Сообщение"
										/>
									)}
								/>
								{errors.message && (
									<div className="input__error">{errors.message.message}</div>
								)}
							</div>
						</div>
					</div>
					<div className="feedback-product__item">
						<button type="submit" className="feedback-product__btn">
							Заказать
						</button>
					</div>
				</form>
				<div className="feedback-product__text">
					<span>
						Я согласен на обработку предоставленных в данной форме персональных
						данных в соответствии с
						<Link href="/">политикой обработки персональных данных</Link>
						<span>
							и даю свое согласие на передачу моих персональных данных по
							открытым каналам связи. Я осознаю риск такой передачи данных.
						</span>
					</span>
				</div>
			</div>
		</section>
	)
}

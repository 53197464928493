import { EyeOutlined } from '@ant-design/icons'
import { Image } from 'antd'

import { useAppSelector } from '@/store'

export function Work({ work }) {
	return (
		<section className="work">
			<div className="container">
				<div className="work__title">
					<h1 className="work__text">Наши работы</h1>
				</div>
				<ul className="work__inner">
					<Image.PreviewGroup>
						{work.map((item) => (
							<li className="work__item" key={item._id}>
								<Image
									className="work__img"
									src={`${process.env.APP_IMAGE}${item.image}`}
									alt="work"
									width={'299'}
									height={'299'}
									preview={{
										mask: (
											<>
												<EyeOutlined
													style={{ fontSize: 25, marginRight: 10 }}
												/>
												<span>Предварительный просмотр</span>
											</>
										),
									}}
								/>
							</li>
						))}
					</Image.PreviewGroup>
				</ul>
			</div>
		</section>
	)
}

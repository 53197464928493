import { UploadOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Upload, UploadFile, UploadProps } from 'antd'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useMemo, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { ProductService } from '@/api'

import { IProduct } from '@/types'

import { requiredField } from '@/utils'

const JoditEditor = dynamic(
	() => import('jodit-react').then((ui) => ui.default),
	{
		ssr: false,
	}
)

interface IForm {
	name: string
	image: File
}

export const AdminProductScreen = ({ slug }: { slug?: string }) => {
	const editor = useRef(null)
	const [page, setPage] = useState('')
	const [product, setProduct] = useState<IProduct>()
	const [fileList, setFileList] = useState<UploadFile[]>([])

	const { query, back } = useRouter()

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IForm>({
		defaultValues: async () => {
			if (slug) {
				const product = await ProductService.getBySlug({
					slug,
				})
				setPage(JSON.parse(product?.page))
				setProduct(product)
				setFileList([
					{
						uid: product._id,
						name: product.slug,
						status: 'done',
						url: `${process.env.REACT_APP_IMAGE}${product.image}`,
					},
				])

				return {
					...product,
				}
			}
		},
	})

	const onSubmit: SubmitHandler<IForm> = (data) => {
		if (product?._id) {
			ProductService.updateById({
				id: product?._id,
				name: data.name,
				image: fileList[0] as unknown as File,
				page: JSON.stringify(page),
				onSuccess: () => {
					back()
				},
			})
		} else {
			ProductService.create({
				name: data.name,
				image: fileList[0] as unknown as File,
				page: JSON.stringify(page),
				onSuccess: () => {
					back()
				},
			})
		}
	}

	const config = useMemo(
		() => ({
			language: 'ru',
		}),
		[]
	)

	const props: UploadProps = {
		onRemove: (file) => {
			const index = fileList.indexOf(file)
			const newFileList = fileList.slice()
			newFileList.splice(index, 1)
			setFileList(newFileList)
		},
		beforeUpload: (file) => {
			setFileList([...fileList, file])

			return false
		},
		fileList,
	}

	return (
		<>
			<section className="productAdd">
				<div className="container">
					<form onSubmit={handleSubmit(onSubmit)} className="productAdd__inner">
						<div className="login__input-wrap input">
							<label className="input__label" htmlFor="name">
								Название продукта
							</label>
							<input
								{...register('name', requiredField)}
								className="input__input"
								type="text"
								placeholder="Название продукта"
							/>
							{errors.name && (
								<div className="input__error">{errors.name.message}</div>
							)}
						</div>
						<Upload {...props} className="upload__product">
							<Button icon={<UploadOutlined />}>Веберите файл</Button>
						</Upload>
						<JoditEditor
							ref={editor}
							value={page}
							config={config}
							onChange={(newContent) => setPage(newContent)}
						/>
						<div className="productAdd__btn">
							{product?._id && (
								<Popconfirm
									title="Удалить продукт"
									description="Вы уверены, что хотите удалить этот продукт?"
									onConfirm={() => {
										ProductService.deleteById(product?._id).then(() => back)
									}}
									// onCancel={() => ()}
									okText="Да"
									cancelText="Нет"
								>
									<Button danger>Удалить</Button>
								</Popconfirm>
							)}
							<Button type="default" className="productAdd__add" onClick={back}>
								Отменить
							</Button>
							<Button
								type="primary"
								htmlType="submit"
								className="productAdd__add"
							>
								Сохранить
							</Button>
						</div>
					</form>
				</div>
			</section>
		</>
	)
}

import { useRouter } from 'next/router'
import { Meta } from 'utils/meta'

import { Feedback, Work } from '@/ui'

export function WorkScreen({ work }) {
	const { asPath } = useRouter()

	return (
		<Meta>
			<Work work={work} />
			{!asPath.includes('admin-panel') && <Feedback />}
		</Meta>
	)
}

export const MAIN_PATH = '/'
export const ERROR_PATH = '/404'
export const AUTH_PATH = '/auth'
export const CONTACT_PATH = '/contact'
export const WORKS_PATH = '/works'
export const PRODUCT_PATH = '/product'
export const ADMIN_INDEX_PATH = '/admin-panel'
export const ADMIN_PRODUCT_PATH = '/admin-panel/products'
export const ADMIN_STOCK_PATH = '/admin-panel/stock'
export const ADMIN_PRODUCT_ADD_PATH = '/admin-panel/products/add'
export const ADMIN_PRODUCT_EDIT_PATH = '/admin-panel/products/edit'
export const ADMIN_WORKS_PATH = '/admin-panel/works'

import Link from 'next/link'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'

import { FeedbackService } from '@/api'

import { nameField } from '@/utils'

interface IForm {
	name: string
	phone: string
}

export function Feedback() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm<IForm>()

	const onSubmit: SubmitHandler<IForm> = async (data) => {
		await FeedbackService.newFeedback({ ...data, reset })
	}

	return (
		<section className="feedback">
			<div className="container">
				<div className="feedback__title">Закажите звонок</div>
				<form onSubmit={handleSubmit(onSubmit)} className="feedback__inner">
					<div className="feedback__item">
						<div className="feedback__input-wrap">
							<label className="feedback__lable" htmlFor="name">
								Имя: <span>*</span>
							</label>
							<div style={{ width: '100%' }}>
								<input
									className="feedback__input"
									id="name"
									type="text"
									placeholder="Ваше имя"
									{...register('name', nameField)}
								/>
								{errors.name && (
									<div className="input__error">{errors.name.message}</div>
								)}
							</div>
						</div>
					</div>
					<div className="feedback__item">
						<div className="feedback__input-wrap">
							<label className="feedback__lable" htmlFor="phone">
								Телефон: <span>*</span>
							</label>
							<div style={{ width: '100%' }}>
								<Controller
									name="phone"
									control={control}
									defaultValue=""
									rules={{
										required: {
											value: true,
											message: 'Поле обязательно для заполнение',
										},
									}}
									render={({ field }) => (
										<InputMask
											value={field.value}
											onChange={field.onChange}
											mask="+7 (999) 999-99-99"
											className="feedback__input"
											placeholder="+7 (___) ___-__-__"
										>
											{(inputProps: any) => <input {...inputProps} />}
										</InputMask>
									)}
								/>
								{errors.phone && (
									<div className="input__error">{errors.phone.message}</div>
								)}
							</div>
						</div>
					</div>
					<div className="feedback__item">
						<button type="submit" className="feedback__btn">
							Заказать
						</button>
					</div>
				</form>
				<div className="feedback__text">
					<span>
						Я согласен на обработку предоставленных в данной форме персональных
						данных в соответствии с
						<Link href="/">политикой обработки персональных данных</Link>
						<span>
							и даю свое согласие на передачу моих персональных данных по
							открытым каналам связи. Я осознаю риск такой передачи данных.
						</span>
					</span>
				</div>
			</div>
		</section>
	)
}

export function Search({
	searchTerm,
	setSearchTerm,
}: {
	searchTerm: string
	setSearchTerm: (str: string) => void
}) {
	return (
		<section className="search bg">
			<div className="container">
				<div className="search__inner">
					<div className="search__left">
						<label className="search__label" htmlFor="2">
							Поиск:
						</label>
						<input
							className="search__input"
							type="search"
							id="2"
							placeholder="Поисковой запрос"
							onChange={({ target }) => setSearchTerm(target.value)}
							value={searchTerm}
						/>
					</div>
				</div>
			</div>
		</section>
	)
}

import Link from 'next/link'

import { CONTACT_PATH, WORKS_PATH } from '@/const'

export function Footer() {
	return (
		<footer className="footer print-hide">
			<div className="footer__top">
				<div className="container">
					<div className="footer__inner">
						<div className="footer__inner-top">
							<div className="socialLinks socialLinks__footer footer__inner-social">
								<Link
									href="https://vk.com/tt_print"
									target="_blank"
									className="socialLinks__link"
									aria-label="social_vk"
								>
									<svg className="socialLinks__icon socialLinks__icon_vk">
										<use xlinkHref="#svg_vk"></use>
									</svg>
								</Link>
							</div>

							<ul className="footer__list">
								<li className="footer__list-item">
									<Link
										href={WORKS_PATH}
										className="footer__list-link"
										title="Наши работы"
									>
										Наши работы
									</Link>
								</li>
								<li className="footer__list-item">
									<Link
										href={CONTACT_PATH}
										className="footer__list-link"
										title="Контакты"
									>
										Контакты
									</Link>
								</li>
							</ul>
						</div>
						<div className="footer__inner-bottom">
							<span className="footer__contact-item">
								<svg className="footer__contact-icon">
									<use xlinkHref="#svg_phone"></use>
								</svg>
								<div
									style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
								>
									<a href="tel:+79878479622">97-96-22</a>
									<a href="tel:+79878479622">+7 (987) 847-96-22</a>
								</div>
							</span>

							<span className="footer__contact-item ">
								<svg className="footer__contact-icon">
									<use xlinkHref="#svg_mail"></use>
								</svg>
								<a href="mailto:ot-oren56@yandex.ru">ot-oren56@yandex.ru</a>
							</span>

							<span className="footer__contact-item">
								<svg className="footer__contact-icon">
									<use xlinkHref="#svg_time"></use>
								</svg>
								Пн. - Пт. : 09:00 - 19:00
							</span>

							<span className="footer__contact-item">
								<svg className="footer__contact-icon">
									<use xlinkHref="#svg_address"></use>
								</svg>
								Ул. Монтажников 7/7, 2 этаж, офис 11
							</span>
						</div>
						<span className="footer-divider"></span>
						<div className="footer__inner-about">
							<span>ИП Фоняк Иван Викторович</span>
							<span>ИНН 561210439408</span>
							<span>ОГРНИП 321565800057190</span>
						</div>
					</div>
				</div>
			</div>
			<div className="footer__bottom">
				<div className="container">
					<div className="footer__copyright">
						2024 © Оперативная типография
					</div>
				</div>
			</div>
		</footer>
	)
}

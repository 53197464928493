import Image from 'next/image'
import Link from 'next/link'
import 'swiper/css'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useWindowSize } from '@/hooks'

import { IMG_URL } from '@/configs'

export function Promo({ stocks }: { stocks: any[] }) {
	const { width } = useWindowSize()

	return (
		<section className="promo">
			<div className="container">
				<div className="promo__slider">
					<Swiper
						modules={[Autoplay]}
						spaceBetween={width > 1024 ? 20 : 15}
						slidesPerView={width > 768 ? 4 : width > 576 ? 3 : 1.7}
						loop
						freeMode={true}
						speed={9000}
						allowTouchMove={false}
						autoplay={{
							delay: 1,
							reverseDirection: true,
							disableOnInteraction: false,
						}}
					>
						{stocks.map((item) => (
							<SwiperSlide className="promo__slide-wrap" key={item._id}>
								<Link className="promo__slide" href="/">
									<Image
										className="promo__slide-img"
										src={`${IMG_URL}${item.image}`}
										alt="sfd"
										width={300}
										height={206}
									/>
								</Link>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	)
}

import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useAppSelector } from '@/store'

export const useAuthRedirect = () => {
	const { token } = useAppSelector((state) => state?.auth)

	const { query, push } = useRouter()

	const redirect = query.redirect ? String(query.redirect) : '/'

	useEffect(() => {
		if (token) push(redirect)
	}, [token, redirect, push])
}

import { Meta } from 'utils/meta'

import { Contacts, DemoWork, Feedback } from '@/ui'

export function ContactScreen({ work }) {
	return (
		<Meta>
			<Contacts />
			<DemoWork work={work} />
			<Feedback />
		</Meta>
	)
}

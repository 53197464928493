import { UploadOutlined } from '@ant-design/icons'
import { Button, GetProp, Image, Upload, UploadFile, UploadProps } from 'antd'
import { useEffect, useState } from 'react'
import { openSuccessNotification } from 'utils/Notification'

import { WorkService } from '@/api'

import { IWork } from '@/types'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (file: FileType): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = (error) => reject(error)
	})

export const WorkAdminScreen = ({ worksInit }: { worksInit: IWork[] }) => {
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [fileList, setFileList] = useState<UploadFile[]>([])

	useEffect(() => {
		if (worksInit?.length) {
			setFileList(
				worksInit?.map((item) => ({
					uid: item._id,
					name: '',
					status: 'done',
					url: `${process.env.APP_IMAGE}${item.image}`,
				}))
			)
		}
	}, [worksInit])

	const getData = async () => {
		const works = await WorkService.getAll()
		setFileList(
			works?.map((item) => ({
				uid: item._id,
				name: '',
				status: 'done',
				url: `${process.env.APP_IMAGE}${item.image}`,
			}))
		)
	}

	const uploadImage = async (options) => {
		const { onSuccess, onError, file, onProgress } = options
		try {
			await WorkService.addImage(file, onProgress)
			onSuccess('Ok')
			getData()
		} catch (err) {
			onError({ err })
		}
	}

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as FileType)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
	}

	const handleChange: UploadProps['onRemove'] = ({ uid: id }) => {
		try {
			WorkService.deleteById(id)
			setFileList(fileList.filter((item) => item.uid !== id))
			openSuccessNotification('Фото удалено')
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<>
			<section className="work">
				<div className="container">
					<div className="work__title">
						<h1 className="work__text">Наши работы</h1>
					</div>
					<Upload
						customRequest={uploadImage}
						multiple
						listType="picture-card"
						fileList={fileList}
						onPreview={handlePreview}
						onRemove={handleChange}
					>
						<Button icon={<UploadOutlined />}>Загрузить</Button>
					</Upload>
					{previewImage && (
						<Image
							wrapperStyle={{ display: 'none' }}
							preview={{
								visible: previewOpen,
								onVisibleChange: (visible) => setPreviewOpen(visible),
								afterOpenChange: (visible) => !visible && setPreviewImage(''),
							}}
							src={previewImage}
							alt=""
						/>
					)}
				</div>
			</section>
		</>
	)
}

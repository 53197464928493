import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

export const Loader = () => {
	return (
		<div className="container loading">
			<Spin
				indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
				style={{ display: 'block', margin: 'auto', paddingBottom: 100 }}
			/>
		</div>
	)
}

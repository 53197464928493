import { EyeOutlined } from '@ant-design/icons'
import { Image } from 'antd'
import Link from 'next/link'

import { IWork } from '@/types'

import { WORKS_PATH } from '@/const'

export function DemoWork({ work }: { work: IWork[] }) {
	if (!work.length) return null

	return (
		<section className="DemoWork">
			<div className="container">
				<div className="DemoWork__title">Наши работы</div>
				<div className="DemoWork__inner">
					<Image.PreviewGroup>
						{work.slice(0, 3)?.map((item) => (
							<div className="DemoWork__item" key={item._id}>
								<Image
									className="DemoWork__img"
									src={`${process.env.APP_IMAGE}${item.image}`}
									alt=""
									width={'299'}
									height={'299'}
									preview={{
										mask: (
											<>
												<EyeOutlined
													style={{ fontSize: 25, marginRight: 10 }}
												/>
												<span>Предварительный просмотр</span>
											</>
										),
									}}
								/>
							</div>
						))}
					</Image.PreviewGroup>
				</div>
				<Link href={WORKS_PATH} className="DemoWork__btn">
					<span>Все работы</span>
					<svg
						className="DemoWork__btn-arrow"
						viewBox="0 0 129 129"
						enableBackground="new 0 0 129 129"
					>
						<g>
							<path d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z" />
						</g>
					</svg>
				</Link>
			</div>
		</section>
	)
}

import Image from 'next/image'
import { useRouter } from 'next/router'

import { IProduct } from '@/types'

import { ADMIN_PRODUCT_EDIT_PATH, PRODUCT_PATH } from '@/const'

export function Categories({ products }: { products: IProduct[] }) {
	const { asPath } = useRouter()

	return (
		<section
			className={`categories ${!asPath.includes('admin-panel') && 'bg'}`}
		>
			<div className="container">
				<ul className="categories__inner">
					{products.length ? (
						products?.map((item) => (
							<li className="categories__item" key={item._id}>
								<a
									className="categories__link"
									href={
										asPath.includes('admin-panel')
											? `${ADMIN_PRODUCT_EDIT_PATH}/${item.slug}`
											: `${PRODUCT_PATH}/${item.slug}`
									}
								>
									<Image
										className="categories__img"
										src={`${process.env.APP_IMAGE}${item.image}`}
										alt="banner"
										width={'72'}
										height={'72'}
									/>
									<span>{item.name}</span>
								</a>
							</li>
						))
					) : (
						<p>Ничего не найдено</p>
					)}
				</ul>
			</div>
		</section>
	)
}

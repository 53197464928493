import { Button } from 'antd'
import { useRouter } from 'next/router'

import { Categories } from '@/ui'

import { IProduct } from '@/types'

import { useAppSelector } from '@/store'

import { ADMIN_PRODUCT_ADD_PATH } from '@/const'

export const ProductListScreen = ({ products }: { products: IProduct[] }) => {
	const { role } = useAppSelector((store) => store.auth)
	const { asPath, push } = useRouter()

	return (
		<>
			<section className="work">
				<div className="container">
					<div className="work__title">
						<h1 className="work__text">Наши продукты</h1>
						{role === 'admin' && asPath.includes('admin-panel') && (
							<Button
								type="primary"
								onClick={() => push(ADMIN_PRODUCT_ADD_PATH)}
							>
								Добавить
							</Button>
						)}
					</div>
					<Categories products={products} />
				</div>
			</section>
		</>
	)
}

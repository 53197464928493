import { AlignRightOutlined, CloseOutlined } from '@ant-design/icons'
import Image from 'next/image'
import Link from 'next/link'

import { isShowMenu, useAppDispatch, useAppSelector } from '@/store'

import { CONTACT_PATH, MAIN_PATH, WORKS_PATH } from '@/const'

export const Header = () => {
	const isShowMenuHtml = useAppSelector((state) => state.app.isShowMenu)
	const dispatch = useAppDispatch()

	const onShowMenu = () => {
		dispatch(isShowMenu(true))
		document.getElementById('body')?.classList.add('_fixed')
	}
	const onCloseMenu = () => {
		dispatch(isShowMenu(false))
		document.getElementById('body')?.classList.remove('_fixed')
	}
	return (
		<div className="header">
			<div className="container">
				<div className="header__top">
					<Link className="header__logo" href={MAIN_PATH}>
						<Image
							className="header__logo-img"
							src={'/img/logo.png'}
							alt="logo"
							width={480}
							height={120}
						/>
					</Link>
					<button className="header__burger" type="button" onClick={onShowMenu}>
						<AlignRightOutlined />
					</button>
					<div className="header__right">
						<a
							href="tel:+79878479622"
							className="header__right-contacts header__right-contacts_mobile-phone"
						>
							<div className="header__right-contacts header__right-contacts_messengers">
								<Image
									src="/img/icon/whatsapp.svg"
									alt="Иконка Whatsapp"
									width={35}
									height={35}
								/>
								<Image
									src="/img/icon/telegram.svg"
									alt="Иконка Telegram"
									width={35}
									height={35}
								/>
							</div>
							+7 (987) 847-96-22
						</a>
						<div className="header__right-phone_mail">
							<a
								href="tel:+73532979622"
								className="header__right-contacts header__right-contacts_phone"
							>
								97-96-22
							</a>
							<a
								href="mailto:ot-oren56@yandex.ru"
								className="header__right-contacts header__right-contacts_mail"
							>
								ot-oren56@yandex.ru
							</a>
						</div>
					</div>
				</div>
				<div className={`header__nav menu ${isShowMenuHtml && '_open'}`}>
					<button className="menu__burger" type="button" onClick={onCloseMenu}>
						<CloseOutlined />
					</button>
					<div className="menu__info">
						<a
							href="tel:+73532979622"
							className="header__right-contacts header__right-contacts_phone"
						>
							97-96-22
						</a>
						<a
							href="tel:+79878479622"
							className="header__right-contacts header__right-contacts_mobile-phone"
						>
							<div className="header__right-contacts header__right-contacts_messengers">
								<Image
									src="/img/icon/whatsapp.svg"
									alt="Иконка Whatsapp"
									width={35}
									height={35}
								/>
								<Image
									src="/img/icon/telegram.svg"
									alt="Иконка Telegram"
									width={35}
									height={35}
								/>
							</div>
							+7 (987) 847-96-22
						</a>
						<a
							href="mailto:ot-oren56@yandex.ru"
							className="header__right-contacts header__right-contacts_mail"
						>
							ot-oren56@yandex.ru
						</a>
					</div>
					<nav className="menu__nav">
						<ul className="menu__list">
							<li className="menu__item">
								<Link
									href={WORKS_PATH}
									title="Наши работы"
									className="menu__link"
								>
									Наши работы
								</Link>
							</li>

							<li className="menu__item">
								<Link
									href={CONTACT_PATH}
									title="Контакты"
									className="menu__link"
								>
									Контакты
								</Link>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	)
}

export * from './Aside/Aside'
export * from './Categories/Categories'
export * from './Contacts/Contacts'
export * from './DemoWork/DemoWork'
export * from './Feedback/Feedback'
export * from './Feedback/FeedbackProduct'
export * from './Footer/Footer'
export * from './Header'
export * from './Loader/Loader'
export * from './Promo/Promo'
export * from './Search/Search'
export * from './Work/Work'
export * from './Modal'

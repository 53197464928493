import { Breadcrumb } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Meta } from 'utils/meta'

import { FeedbackProduct } from '@/ui'

import { IProduct } from '@/types'

import { PRODUCT_PATH } from '@/const'

export function ProductScreen({
	product,
	products,
}: {
	product: IProduct
	products: IProduct[]
}) {
	const {
		query: { slug },
	} = useRouter()

	return (
		<Meta title={product.name}>
			<section className="product">
				<div className="container">
					<div className="product__inner">
						<ul className="product__left">
							{products?.map((item) => (
								<li className="product__left-item" key={item.slug}>
									<Link
										href={`${PRODUCT_PATH}/${item.slug}`}
										className={`product__left-link ${slug == item.slug && 'active'}`}
									>
										{item.name}
									</Link>
								</li>
							))}
						</ul>
						<div className="product__content">
							<Breadcrumb
								items={[
									{ title: 'Главная', href: '/' },
									{
										title: product?.name,
										href: `${PRODUCT_PATH}/${product?.slug}`,
									},
								]}
							/>
							<div className="product__title">{product?.name}</div>
							{product?.page && (
								<div>
									<div
										dangerouslySetInnerHTML={{
											__html: JSON.parse(product?.page),
										}}
										className="product__inner-text"
									></div>
									<FeedbackProduct product={product} />
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
		</Meta>
	)
}
